import styled from 'styled-components';
import sizes from '../../../util/sizes';

interface IEmptyContentWrapper {
  reverseHeight?: string | null;
}
export const EmptyContentWrapper = styled.div<IEmptyContentWrapper>`
  position: relative;
  margin: 0 auto;
  max-width: ${sizes.XL};
  height: ${({ reverseHeight }) =>
    reverseHeight ? `calc(100vh - ${reverseHeight})` : 'calc(100vh - 116px)'};
`;

export const EmptyMainContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const EmptyTitleText = styled.div`
  font-family: BasierCircle-Medium;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 16px;
  margin-top: 34px;
  color: #263a54;
`;

export const EmptyDescriptionText = styled.div`
  font-family: BasierCircle;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  width: 300px;
  color: #334d70;
`;
