import styled from 'styled-components';
import { SlidingUp } from '../../../styles/animations';
import colors from '@pickles/shared/utils/colors';
import sizes from '../../../util/sizes';

interface ModalProps {
  size?: string;
  isOpened?: boolean;
}
const modalBoxShadow = ' 0px 6px 50px rgba(0, 0, 0, 0.09);';

export const CloseIcon = styled.img`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

export const Modal = styled.div<ModalProps>`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: ${({ isOpened }) => (isOpened ? 'block' : 'none')};
  background: rgba(49, 54, 62, 0.6);
  z-index: 100;
`;

export const ModalDialog = styled.div<ModalProps>`
  position: relative;
  width: fit-content;
  height: ${({ size }) => (size === 'sm?' ? '360px' : 'initial')};
  overflow: auto;
  margin: 0 auto;
  margin-top: 100px;
  border: none;
  box-shadow: ${modalBoxShadow};
  padding: 25px;
  background: #fff;
  border-radius: 10px;
  animation: ${SlidingUp};
  animation-duration: 0.3s;
  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${sizes.M}) {
    max-width: calc(100% - 40px);
  }
`;

export const ModalActions = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 1em;
`;

export const ShareContentWrapper = styled.div`
  font-family: 'BasierCircle';
  color: ${colors.rhino_black};
  line-height: 1.5;

  .makeStyles-container-1 {
    padding: 0;
  }
  .makeStyles-iconContainer-3 {
    padding: 10px 0 10px;
  }

  .makeStyles-copyContainer-5 {
    padding: 0;
    border: none;
    background: ${colors.white};
  }

  .makeStyles-copyUrl-6 {
    border: 1px solid ${colors.mischka};
    border-radius: 6px;
    padding: 10px;
    font-family: 'BasierCircle';
    font-size: 16px;
    font-weight: 500;
    color: ${colors.rhino_black};
    overflow: hidden;
    max-width: calc(100% - 95px);
  }

  .makeStyles-copyIcon-7 p {
    background: ${colors.blumine_main_blue};
    padding: 12px 20px;
    border-radius: 8px;
    color: ${colors.white};
  }
`;

export const ShareIconsRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const ShareTitle = styled.div`
  font-size: 21px;
  font-weight: 600;
`;

export const ShareDescription = styled.div`
  font-size: 16px;
  padding-top: 15px;
`;

export const ShareLinkCopy = styled.div`
  > div:first-child {
    padding: 0;

    > div:first-child {
      padding: 6px;
    }

    > div:last-child {
      padding: 0;
      border: none;
      background: ${colors.white};

      @media (max-width: ${sizes.S}) {
        flex-direction: column;
        row-gap: 10px;
        align-items: start;
      }

      > div:first-child {
        border: 1px solid ${colors.mischka};
        border-radius: 6px;
        padding: 10px;
        width: 300px;
        font-family: 'BasierCircle';
        font-size: 16px;
        font-weight: 500;
        color: ${colors.rhino_black};
        overflow: hidden;
        max-width: calc(100% - 95px);

        @media (max-width: ${sizes.S}) {
          max-width: 100%;
        }
      }

      > div:last-child {
        background: ${colors.blumine_main_blue};
        /* padding: 12px 20px; */
        border-radius: 8px;
        color: ${colors.white};
        width: 100px;
        position: relative;
        margin-left: 10px;

        @media (max-width: ${sizes.S}) {
          position: initial;
          width: 100%;
          text-align: center;
          margin-left: 0;
        }

        > p {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
`;

export const ModalCloseButton = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    height: 18px;
    width: 2px;
    transform: rotate(-45deg);
    background: ${colors.rhino_black};
    position: absolute;
    top: 6px;
    right: 14px;
    border-radius: 2px;
    display: block;
  }

  &:after {
    transform: rotate(45deg);
  }
`;
