import { IAuction, IItem } from '@pickles/shared/models';
import colors from '@pickles/shared/utils/colors';
import { formatDurationDiff } from '@pickles/shared/utils/date';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppHooks } from '../../../../app/redux/hooks';
import { AuctionTimeIcon } from '../../Icons';
import { ClockMessageContainer, ClockMessageText } from './styles';

type ClockMessageProps = {
  item?: IItem.Item | null;
  auction?: IAuction.Auction | null;
  id?: string;
};

export const ClockMessage: FC<ClockMessageProps> = ({ item, auction, id }) => {
  const now = AppHooks.useApplicationTimer();
  const { t } = useTranslation();
  const [startAt, setStartAt] = useState<number>();
  const [endAt, setEndAt] = useState<number>();

  const isLiveAuction = item?.isLive || auction?.eventType === IAuction.AUCTION_TYPES.LIVE;

  let startTime = 0; 
  let endTime = 0;
  
  if (item?.bidding?.startTime) {
    startTime = item.bidding.startTime
  } else if (item?.saleEvent?.startAt) {
    startTime = moment(item?.saleEvent?.startAt).valueOf()
  }

  if (item?.bidding?.endTime) {
    endTime = item.bidding.endTime
  } else if (item?.saleEvent?.endAt) {
    endTime = moment(item?.saleEvent?.endAt).valueOf()
  }

  const showToBeAnnouncedBanner = item?.isHold;
  const showAuctionEnded = (endAt && now > endAt) || item?.offer;
  
  useEffect(() => {
    if (item && startTime && endTime) {
      setStartAt(moment(startTime).valueOf());
      setEndAt(moment(endTime).valueOf());
    } else if (auction && auction.startAt && auction.endAt) {
      setStartAt(moment(auction.startAt).valueOf());
      setEndAt(moment(auction.endAt).valueOf());
    }
  }, [item, auction, startTime, endTime]);

  if (startAt && now < startAt) {
    const duration = formatDurationDiff(now, startAt);
    const text = ` ${t('labels:start_in')} ${duration}`;
    return (
      <ClockMessageContainer id={id}>
        <AuctionTimeIcon strokeColor={colors.winning} />
        <ClockMessageText color={colors.green} id="timer">{text}</ClockMessageText>
      </ClockMessageContainer>
    );
  }

  if (endAt && now < endAt) {
    if (isLiveAuction) {
      return <ClockMessageContainer id={id} />;
    }

    const duration = formatDurationDiff(now, endAt);
    const text = ` ${t('labels:end_in')} ${duration}`;
    return (
      <ClockMessageContainer id={id}>
        <AuctionTimeIcon strokeColor={colors.outbid} />
        <ClockMessageText color={colors.red} id="timer">{text}</ClockMessageText>
      </ClockMessageContainer>
    );
  }

  if (showAuctionEnded) {
    return (
      <ClockMessageContainer id={id}>
        <AuctionTimeIcon strokeColor={colors.outbid} />
        <ClockMessageText color={colors.red} id="timer">{t('labels:auction_ended')}</ClockMessageText>
      </ClockMessageContainer>
    );
  }

  if (showToBeAnnouncedBanner) {
    return (
      <ClockMessageContainer id={id}>
        <AuctionTimeIcon strokeColor={colors.jaffa} />
        <ClockMessageText id="timer">{t('labels:to_be_announced')}</ClockMessageText>
      </ClockMessageContainer>
    );
  }

  return <ClockMessageContainer id={id} />;
};
