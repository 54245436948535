import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { IBidding, IItem } from '@pickles/shared/models';
import { EbidAuctionHooks, LiveAuctionHooks } from '../../app/redux/hooks';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import colors from '@pickles/shared/utils/colors';

interface BidStatusLabelProps {
  item: IItem.Item;
}

const BidStatusLabel: FC<BidStatusLabelProps> = ({ item }) => {
  const { t } = useTranslation();
  const ebidConnectionStatus = EbidAuctionHooks.useConnectionStatus();
  const liveConnectionStatus = LiveAuctionHooks.useConnectionStatus();
  const isConnected = useMemo(() => {
    return (
      (item.isEbid && ebidConnectionStatus?.connected) ||
      (item.isLive && liveConnectionStatus?.connected) ||
      false
    );
  }, [item, ebidConnectionStatus, liveConnectionStatus]);
  const status = item.bidding?.status;

  const isWinning = _.includes(
    [IBidding.STATUS.WINNING, IBidding.STATUS.WON, IBidding.STATUS.OFFER],
    status,
  );
  const isOutbidLost = _.includes([IBidding.STATUS.OUTBID, IBidding.STATUS.LOST], status);

  if (!status || (!isWinning && !isOutbidLost)) {
    return null;
  }

  return (
    <StatusContainer isWinning={isWinning && isConnected} id="my_ebids_status">
      <StatusText id="my_ebids_status_text">{isConnected ? status : t('errors:offline')}</StatusText>
    </StatusContainer>
  );
};

export default BidStatusLabel;

const StatusContainer = styled.div<{ isWinning: boolean }>`
  display: flex;
  background: ${({ isWinning }) =>
    isWinning
      ? `linear-gradient(180deg, ${colors.green_gradient_start}, ${colors.green_gradient_end})`
      : `linear-gradient(180deg, ${colors.red_gradient_start}, ${colors.red_gradient_end})`};
  width: 100px;
  height: 36px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
`;

const StatusText = styled.div`
  font-family: BasierCircle;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: white;
  text-transform: uppercase;
`;
