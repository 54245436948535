import Image from 'next/image';
import React, { MouseEventHandler, useCallback } from 'react';
import { IconButtonContainer, IconSizes } from './IconButton.style';
type PropsType = {
  src: string;
  onClick?: MouseEventHandler | undefined;
  id?: string;
  size?: IconSizes;
};
export const IconButton = ({ src, onClick, id, size }: PropsType) => {
  const onIconButtonClickHandler = useCallback(
    (e) => {
      if (onClick) {
        onClick(e);
      }
    },
    [onClick],
  );

  return (
    <IconButtonContainer id={id} onClick={onIconButtonClickHandler} size={size}>
      <Image src={src} alt="Icon Button" />
    </IconButtonContainer>
  );
};
