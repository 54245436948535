import React from 'react';

interface ISvgLike {
  color?: string;
  id?: string;
}

const SvgLike = ({ color = 'none', id }: ISvgLike) => {
  return (
    <svg
      id={id}
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.171 2.85234C15.4009 1.05011 12.5303 1.05011 10.7601 2.85234C10.4428 3.17541 10.2036 3.54232 9.99965 3.92423C9.79566 3.54232 9.55654 3.17425 9.23809 2.85119C7.46791 1.04895 4.59732 1.04895 2.82715 2.85119C1.05697 4.65343 1.05697 7.57601 2.82715 9.37825L9.99965 16.4995L17.171 9.37825C18.9423 7.57601 18.9423 4.65458 17.171 2.85234Z"
        stroke="#334D70"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgLike;
