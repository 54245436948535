import moment, { ISO_8601 } from 'moment';

export function formatAuctionStartDate(date: string) {
  return moment(date).format('MMM D');
}

export function formatAuctionStartTime(date: string) {
  return moment(date).format('h:mm A');
}

export function formatDate(date: string, format: string) {
  return moment(date).format(format);
}

export function isISODate(date?: number | string | Date) {
  return moment(date, ISO_8601, true).isValid();
}

export function isUnixDate(date?: number | string | Date) {
  return moment(date, 'X', true).isValid();
}

const MILLISECONDS_PER_HOUR = 60 * 60 * 1000;
const MILLISECONDS_THREE_HOUR = 3 * MILLISECONDS_PER_HOUR;
const MILLISECONDS_PER_DAY = 24 * MILLISECONDS_PER_HOUR;

/**
 * Calculate the time difference in `d h m s` format
 *
 * More than 24 hours -> `__d __h`
 *
 * Less than 3 hours -> `__h __m __s`
 *
 * Less than 1 hour -> `__m __s`
 * @param baseTime base time to calculate the difference
 * @param towardTime the time toward which to calculate the difference
 * @returns
 */
export const formatDurationDiff = (
  baseTime: moment.MomentInput,
  towardTime: moment.MomentInput,
) => {
  const diff = moment(towardTime).diff(baseTime);
  const duration = moment.duration(diff);

  switch (true) {
    case diff >= MILLISECONDS_PER_DAY: // more than 24 hours
      return `${Math.floor(duration.asDays())}d ${duration.hours()}h`;
    case diff <= MILLISECONDS_PER_HOUR: // less than 1 hour
      return `${duration.minutes()}m ${duration.seconds()}s`;
    case diff <= MILLISECONDS_THREE_HOUR: // less than 3 hours
      return `${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
    default:
      return `${duration.hours()}h ${duration.minutes()}m`;
  }
};
