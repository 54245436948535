import React, { useEffect, useState, useRef } from 'react';
import { Modal, ModalDialog, ModalCloseButton } from './styles';

interface ModalProps {
  children?: React.ReactNode;
  isOpened: boolean;
  onClickOutside: () => void;
  size?: string;
  isFromLiveAuctionPage?: boolean;
  scrollTop?: number;
  isCloseButton?: boolean;
  id?: string;
}

export const BaseModal = ({
  children,
  isOpened = false,
  onClickOutside,
  size,
  isFromLiveAuctionPage = false,
  scrollTop,
  isCloseButton,
  id,
}: ModalProps) => {
  const [el] = useState(document.createElement('div'));
  const modalRef = useRef<HTMLDivElement>(el);

  const onClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    onClickOutside();
  };

  const closeByPress = (e: any) => {
    if (e.keyCode === 27) {
      onClickOutside();
    }
  };

  useEffect(() => {
    if (isOpened) {
      document.addEventListener('keydown', closeByPress);
    }
    return () => {
      document.removeEventListener('keydown', closeByPress);
    };
  }, [isOpened]);

  return (
    <Modal
      id={id}
      scrollTop={scrollTop}
      isOpened={isOpened}
      onClick={onClickHandler}
      isFromLiveAuctionPage={isFromLiveAuctionPage}
    >
      <ModalDialog
        isFromLiveAuctionPage={isFromLiveAuctionPage}
        onClick={(e) => e.stopPropagation()}
        ref={modalRef}
        size={size}
      >
        {isCloseButton && <ModalCloseButton onClick={() => onClickOutside()} />}
        {children}
      </ModalDialog>
    </Modal>
  );
};
