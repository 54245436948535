import styled from 'styled-components';

interface IIconButtonContainer {
  size?: IconSizes;
}
const smallIconSize = '36px';
const bigIconSize = '44px';

export enum IconSizes {
  Small = 'Small',
  Big = 'Big',
}

export const IconButtonContainer = styled.div<IIconButtonContainer>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${({ size }) => (size === IconSizes.Big ? bigIconSize : smallIconSize)};
  height: ${({ size }) => (size === IconSizes.Big ? bigIconSize : smallIconSize)};
  margin-left: 8px;
  border: 1px solid #e1e8fa;
  border-radius: 8px;
  background: #ffffff;
  cursor: pointer;
  z-index: 1;
`;
