import React, { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { SUPPORT_PHONE } from '@pickles/shared/utils/config';
import { BaseModal } from '../Shared/Modal';

import { ModalContainer, ModalTitle, ModalDescription, ModalButton } from './styles';
import { useRouter } from 'next/router';
import Portal from '../Portal';

interface ILiveModalProps {
  isOpened: boolean;
  setIsOpened?: Dispatch<SetStateAction<boolean>>;
  redirectPath?: string;
}

// TO REMOVE ALL RELATED-COMPONENTS ONCE LIVE AUCTION IS READY
const LiveAuctionModal: FC<ILiveModalProps> = ({ isOpened, setIsOpened, redirectPath }) => {
  const { t } = useTranslation();
  const router = useRouter();

  const handleBtnPress = () => {
    if (redirectPath) {
      router.push(redirectPath);
    }

    setIsOpened && setIsOpened(false);
  };

  return (
    <Portal wrapperId="live-modal-wrapper">
      <BaseModal isOpened={isOpened} onClickOutside={() => setIsOpened && setIsOpened(false)}>
        <ModalContainer>
          <ModalTitle>{t('titles:coming_soon')}</ModalTitle>
          <ModalDescription>
            {t('infos:live_coming_soon', { phone: SUPPORT_PHONE })}
          </ModalDescription>
          <ModalButton onClick={handleBtnPress}>{t('buttons:continue')}</ModalButton>
        </ModalContainer>
      </BaseModal>
    </Portal>
  );
};

export default LiveAuctionModal;
