import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, MouseEventHandler, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImageGallery from 'react-image-gallery';
import { useDispatch } from 'react-redux';

import { WEBAPP_AUCTION_ROUTE_PATH, WEBAPP_ITEM_ROUTE_PATH } from '@pickles/shared/config';
import Config from '@pickles/shared/config/env';
import { IBidding, IItem } from '@pickles/shared/models';
import { appActions, itemActions } from '@pickles/shared/redux/actions';
import colors from '@pickles/shared/utils/colors';
import {
  getLotInfo,
  imageUrlWithResizer,
  numberToAmountLabel,
} from '@pickles/shared/utils/helpers';
import { getAuctionItemRef, getAuctionRefByAuction } from '@pickles/shared/utils/routes';

import { AppHooks, DashboardHooks, ItemHooks, UserHooks } from '../../app/redux/hooks';
import { auctionItemFallbackImage } from '../../util/constants';
import { getDynamicLInk } from '../../util/dynamicLink';
import { getDefaultImage } from '../../util/helpers';
import LiveAuctionModal from '../LiveAuctionModal';
import ShareModalPortal from '../Portal/ShareModalPortal';
import { IconButton } from '../Shared/Button/IconButton';
import { CardPinIcon, PrebidIcon, PrebidsIcon, shareIcon } from '../Shared/Icons';
import { ClockMessage } from '../Shared/Messages/ClockMessage';
import SvgLike from '../Shared/SvgLikeIcon';
import {
  AmountBlock,
  AuctionDetailImageRow,
  AuctionDetailItemContainer,
  AuctionDetailItemInfo,
  AuctionDetailItemRow,
  AuctionDetailItemRowLeft,
  AuctionModelName,
  AuctionPlaceMapContainer,
  BidLabel,
  BidLabelBlock,
  BorderWrapper,
  CarDetailStatus,
  HorizontalsContainer,
  ImageContainer,
  LeftBlock,
  LikeButtonWrapper,
  MileageRowContainer,
  PrebidsStatusContent,
  PrebidsStatusLabel,
  PrebidsStatusValue,
  RightBlock,
  SetMaxButton,
  StatusContainer,
  ViewButton,
} from './styles';
import BidStatusLabel from './BidStatusLabel';
import { isAuctionRunning } from '@pickles/shared/models/helpers';

type ItemCardProps = {
  item: IItem.Item;
  onClickItem?: (item: IItem.Item) => void;
};

export const ItemCard: FC<ItemCardProps> = ({ item, onClickItem }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const now = AppHooks.useApplicationTimer();
  const router = useRouter();
  const route = router.route;
  const user = UserHooks.useUserAccount();
  const isFavorite = ItemHooks.useItemFavorite(item.id);
  const auction = DashboardHooks.useAuction(item.saleEvent?.id);
  const auctionRef = auction && getAuctionRefByAuction(auction);
  const auctionItemRef = getAuctionItemRef(item);
  const itemStatus = item.bidding?.status;

  const onMyEbids = route === '/profile/ebids';
  const closedStatuses = Object.values(IBidding.CLOSED_STATUS);
  const eBidEnded = onMyEbids && closedStatuses.includes(itemStatus!);
  const isLiveAuction = item?.isLive;
  const isEbidAuction = item?.isEbid;
  const isItemOffer = item.bidding?.isOffer;

  const startAt = item.bidding?.startTime || auction?.startAt;
  const endAt = item.bidding?.endTime || auction?.endAt;
  const isLiveAuctionPast = item.isLive && now > new Date(endAt).valueOf();
  const isRunning = isAuctionRunning(item.eventType, auction?.isLiveStarted, startAt, endAt, now);

  const [shareLink, setShareLink] = useState('');
  const [openShare, setOpenShare] = useState(false);
  const [isLiveModalVisible, setIsLiveModalVisible] = useState(false);

  const onLikeBtnClickHandler = useCallback(
    (e: any) => {
      e.stopPropagation();
      dispatch(itemActions.setFavorite(item.id, !isFavorite, item.saleEvent?.id));
    },
    [isFavorite, dispatch, item],
  );

  const getImageSource = () => {
    switch (true) {
      case !!item.images:
        return item.images.map((image) => ({ original: imageUrlWithResizer(image, 540) }));
      default:
        return [{ original: item.image }];
    }
  };

  const city = item?.yard?.city;
  const odometer = item?.vehicle?.odometer;
  const odounit = item?.vehicle?.odoUnits;
  const conditionType = item?.conditionType;
  const lotInfo = getLotInfo(odometer, odounit, conditionType);
  const hasOffer = item.offer && !isNaN(item.offer?.offerAmount);
  const itemHref = `/${WEBAPP_ITEM_ROUTE_PATH}/${auctionItemRef}`;

  const getPriceLabel = () => {
    switch (true) {
      case eBidEnded:
        return t('labels:your_bid');
      case onMyEbids:
        return t('labels:current_bid');
      case hasOffer:
        return t('labels:offer_amount');
      default:
        return t('labels:deposit_amount');
    }
  };

  const getPriceValue = () => {
    switch (true) {
      case eBidEnded:
        return numberToAmountLabel(item.bidding?.lastBidAmount);
      case onMyEbids:
        return numberToAmountLabel(item.bidding?.currentBidAmount);
      case hasOffer:
        return numberToAmountLabel(item.offer?.offerAmount);
      default:
        return numberToAmountLabel(item?.depositAmt);
    }
  };

  const onClickShare: MouseEventHandler<Element> = async (e) => {
    if (item.bidding?.isLost) {
      return;
    }

    e.stopPropagation();
    const dynamicLink = await getDynamicLInk(itemHref);
    setShareLink(dynamicLink);
    setOpenShare(true);
  };

  const onItemClickHandler = useCallback(() => {
    if (item.bidding?.isLost) {
      return;
    }

    router.push(itemHref);
  }, [itemHref, router]);

  const showLiveComingSoonModal = () => {
    !isLiveModalVisible && setIsLiveModalVisible(true);
  };

  const handleAttendButtonPress: MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();
    if (Config.canAttendLiveAuction()) {
      const auctionRef = getAuctionRefByAuction(auction);
      if (user) {
        if (user?.status === 'PENDING') {
          dispatch(
            appActions.showAlert({
              alertType: 'alert',
              title: 'Pickles',
              message: t('infos:account_pending_title_new'),
            }),
          );
          return;
        }
        router.push(`/${WEBAPP_AUCTION_ROUTE_PATH}/${auctionRef}/live`);
      } else {
        dispatch(
          appActions.showAlert({
            alertType: 'alert',
            title: 'Pickles',
            message: t('infos:not_logged'),
          }),
        );
      }
    } else {
      showLiveComingSoonModal();
    }
  };

  const renderStatusLabel = () => {
    if (!!item.bidding && onMyEbids) {
      return (
        <StatusContainer id="status-container">
          <IconButton src={shareIcon} onClick={onClickShare} id="share_button" />
          <BidStatusLabel item={item} />
        </StatusContainer>
      );
    }
    return <IconButton src={shareIcon} onClick={onClickShare} />;
  };

  const renderBadge = () => {
    const shouldHideBadge = (auctionRef && router.asPath.includes(auctionRef)) || onMyEbids;

    if (shouldHideBadge) {
      return null;
    }

    switch (true) {
      case item.isHold:
        return <CarDetailStatus>{t('status:on_hold')}</CarDetailStatus>;
      case item.isLive:
        return <CarDetailStatus>{t('status:live_auction')}</CarDetailStatus>;
      case item.isEbid:
        return <CarDetailStatus>{t('status:e_bidding')}</CarDetailStatus>;
      default:
        return null;
    }
  };

  const renderEbidButton = () => {
    switch (true) {
      case item.bidding?.status === IBidding.STATUS.WINNING:
        return (
          <ViewButton id="my_ebids_bid_button" onClick={onItemClickHandler}>
            <span>{t('buttons:view_details')}</span>
          </ViewButton>
        );
      case item.bidding?.status === IBidding.STATUS.OUTBID:
        return (
          <ViewButton id="my_ebids_bid_button" onClick={onItemClickHandler}>
            <span>{t('buttons:update_bid')}</span>
          </ViewButton>
        );
      case closedStatuses.includes(item.bidding?.status):
        return (
          <ViewButton id="my_ebids_bid_button" onClick={onItemClickHandler}>
            <span>{t('labels:auction_ended')}</span>
          </ViewButton>
        );
      default:
        return (
          <ViewButton id="bid_now" onClick={onItemClickHandler}>
            <span>{t('buttons:bid_now')}</span>
          </ViewButton>
        );
    }
  };

  const renderButton = () => {
    const showAttendButton = isRunning && isLiveAuction;
    const showEbidButton = isEbidAuction && onMyEbids;
    const showBidNowButton = isRunning && isEbidAuction;
    const showSetMaxBidButton = !isRunning && isLiveAuction && !isLiveAuctionPast && !isItemOffer;

    switch (true) {
      case showAttendButton:
        return (
          <SetMaxButton id={'attend-btn'} onClick={handleAttendButtonPress}>
            <span>{'+ ' + t('buttons:attend')}</span>
          </SetMaxButton>
        );
      case showSetMaxBidButton:
        return (
          <SetMaxButton id={'set-max-btn'} onClick={onItemClickHandler}>
            <PrebidIcon strokeColor={colors.white} />
            <span>
              {item.prebid?.amount ? t('buttons:update_max_bid') : '  ' + t('buttons:set_max_bid')}
            </span>
          </SetMaxButton>
        );
      case showEbidButton:
        return renderEbidButton();
      case showBidNowButton:
        return (
          <SetMaxButton id={'bid-now-btn'} onClick={onItemClickHandler}>
            <span>{t('buttons:bid_now')}</span>
          </SetMaxButton>
        );
      default:
        return (
          // used on: offer page
          <Link href={itemHref} passHref={true}>
            <ViewButton id={'view-detail-btn'}>{t('buttons:view_details')}</ViewButton>
          </Link>
        );
    }
  };

  const checkRedBorderVisible = () => {
    if (item.bidding?.isOutbid) {
      return true;
    }
    return false;
  };

  const imageSource = getImageSource();
  const isBigSize = true;
  const defaultImage = () => {
    const defaultImage = getDefaultImage(item.eventCategory, isBigSize);
    return [{ original: defaultImage.src }];
  };

  const isRedBorderVisible = checkRedBorderVisible();

  const handleOnClickItem = () => {
    if (item.bidding?.isLost) {
      dispatch(
        appActions.showAlert({
          alertType: 'alert',
          title: 'Pickles',
          message: t('labels:auction_ended'),
        }),
      );

      return;
    }

    onClickItem ? onClickItem(item) : router.push(itemHref);
  };

  return (
    <AuctionDetailItemContainer
      id="watch-list"
      isModalVisible={isLiveModalVisible}
      onClick={handleOnClickItem}
    >
      <BorderWrapper isVisible={isRedBorderVisible}>
        {/* <>
          <BorderRight />
          <BorderTop />
          <BorderBottom />
        </> */}
        <ImageContainer onClick={(e) => e.stopPropagation()}>
          <ImageGallery
            onClick={handleOnClickItem}
            onErrorImageURL={auctionItemFallbackImage}
            items={imageSource?.length ? imageSource : defaultImage()}
            showThumbnails={false}
            showNav={true}
            showPlayButton={false}
            showFullscreenButton={false}
            lazyLoad={true}
          />
        </ImageContainer>
      </BorderWrapper>
      <AuctionDetailImageRow>
        {renderStatusLabel()}
        {renderBadge()}
        {!item.bidding?.isLost && (
          <LikeButtonWrapper onClick={onLikeBtnClickHandler} id={'like-btn'}>
            <SvgLike color={isFavorite ? colors.blumine_main_blue : 'none'} />
          </LikeButtonWrapper>
        )}
      </AuctionDetailImageRow>
      <AuctionDetailItemInfo>
        <HorizontalsContainer>
          <LeftBlock>
            <ClockMessage item={item} />
            <AuctionModelName id="item_name">{item.name}</AuctionModelName>
            <AuctionDetailItemRowLeft>
              <AuctionDetailItemRow>
                <p>
                  Stock: <span id="stock_number">{` ${item.stockNum}`}</span>
                </p>
              </AuctionDetailItemRow>
              <p />
              <AuctionDetailItemRow>
                <p>
                  Lot: <span id="lot_number">{` ${item?.saleEvent?.lotNum || t('labels:n_a')}`}</span>
                </p>
              </AuctionDetailItemRow>
            </AuctionDetailItemRowLeft>
            <MileageRowContainer id="lot_info">{lotInfo}</MileageRowContainer>
            {city && (
              <AuctionPlaceMapContainer>
                <CardPinIcon />
                <div>
                  <p id="location">{city}</p>
                </div>
              </AuctionPlaceMapContainer>
            )}
          </LeftBlock>
          <RightBlock>
            <BidLabelBlock>
              <BidLabel id="my_ebids_label">{getPriceLabel()}</BidLabel>
              <AmountBlock id="my_ebids_amount">{getPriceValue()}</AmountBlock>
            </BidLabelBlock>
            {renderButton()}
          </RightBlock>
        </HorizontalsContainer>

        {item.prebid && (
          <PrebidsStatusContent>
            <PrebidsIcon width={28} height={28} strokeWidth={1.5} />
            <PrebidsStatusLabel>{t('labels:your_max_bid') + ': '}</PrebidsStatusLabel>
            <PrebidsStatusValue>{numberToAmountLabel(item.prebid.amount ?? 0)}</PrebidsStatusValue>
          </PrebidsStatusContent>
        )}
      </AuctionDetailItemInfo>
      <ShareModalPortal
        isOpen={openShare}
        url={shareLink}
        onClickClose={() => setOpenShare(false)}
      />
      <LiveAuctionModal isOpened={isLiveModalVisible} setIsOpened={setIsLiveModalVisible} />
    </AuctionDetailItemContainer>
  );
};
