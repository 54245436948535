import colors from '@pickles/shared/utils/colors';
import styled, { CSSProperties } from 'styled-components';

export const ClockMessageContainer = styled.div`
  display: flex;
  align-items: center;

  > div {
    font-family: 'BasierCircle';
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    white-space: nowrap;
    margin-left: 6px;
    font-weight: 400;
    span {
      max-width: 200px;
      // margin-left: 4px;
    }
  }
`;

interface ClockMessageTextProps {
  color?: CSSProperties['color'];
}

export const ClockMessageText = styled.p<ClockMessageTextProps>`
  font-family: 'BasierCircle-Medium';
  font-style: normal;
  font-size: 15px;
  line-height: 26px;
  white-space: nowrap;
  margin-left: 6px;
  font-weight: 400;
  color: ${(props) => (props.color ? props.color : colors.jaffa)};
`;
