import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';

import { IItem } from '@pickles/shared/models';
import { webActions } from '@pickles/shared/redux/actions';

import { ItemCard } from '../ItemCard';
import { AuctionDetailItemsContainer } from './ItemsListing.style';

export type ItemsListingProps = {
  items: IItem.Item[];
  onClickItem?: (item: IItem.Item) => void;
};

const ItemsListing: FC<ItemsListingProps> = ({ items, onClickItem }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const path = router.asPath;

  useEffect(() => {
    dispatch(webActions.setSavedPath({ path }));
  }, [path, dispatch]);

  const itemCards = items.map((item) => {
    return <ItemCard item={item} key={item.id} onClickItem={onClickItem} />;
  });

  return (
    <AuctionDetailItemsContainer id="auction-detail-items-container">
      {itemCards}
    </AuctionDetailItemsContainer>
  );
};

export default ItemsListing;
