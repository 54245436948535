import {
  DYNAMIC_LINK_BASE,
  FIREBASE_API_KEY,
  ANDROID_PACKAGE_NAME,
  IOS_BUNDLE_ID,
} from '@pickles/shared/utils/config';

export const getDynamicLInk = async (link: string) => {
  const baseUrl = window?.location?.origin;

  const res = await fetch(
    `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${FIREBASE_API_KEY}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        dynamicLinkInfo: {
          domainUriPrefix: `${DYNAMIC_LINK_BASE}`,
          link: `${baseUrl}${link}`,
          androidInfo: {
            androidPackageName: ANDROID_PACKAGE_NAME,
          },
          iosInfo: {
            iosBundleId: IOS_BUNDLE_ID,
          },
        },
      }),
    },
  );
  if (res.ok) {
    const response = await res.json();
    return response.shortLink;
  }
};
