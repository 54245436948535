import styled from 'styled-components';
import sizes from '../../util/sizes';
import colors from '@pickles/shared/utils/colors';

export const AuctionDetailItemsContainer = styled.div`
  display: grid;
  align-items: flex-start;
  grid-template-columns: repeat(3, 370px);
  gap: 30px;
  margin-top: 24px;
  z-index: 0;
  justify-content: center;

  @media (max-width: ${sizes.XL}) {
    grid-template-columns: repeat(2, 370px);
  }

  @media (max-width: ${sizes.M}) {
    grid-template-columns: repeat(2, 340px);
    justify-content: center;
  }

  @media (max-width: 740px) {
    grid-template-columns: 1fr;
  }
`;

export const AuctionDetailItemContainer = styled.div`
  position: relative;
  width: 370px;
  cursor: pointer;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div {
    margin-top: 4px;
  }
  svg {
    width: 24px !important;
  }
  > div {
    font-family: BasierCircle;
  }

  .image-gallery-image {
    max-height: initial;
    min-height: 270px !important;
    max-height: 270px !important;
    border-radius: 8px;

    @media (max-width: ${sizes.XL}) {
      min-height: 30vh;
      max-height: 30vh !important;
    }
    @media (max-width: ${sizes.L}) {
      min-height: 26vh;
      max-height: 26vh !important;
    }
    @media (max-width: ${sizes.M}) {
      min-height: initial;
      max-height: initial !important;
    }
  }
`;

export const AuctionDetailItemRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  color: #334d70;
  span {
    font-size: 15px;
    font-family: 'BasierCircle-Medium';
    font-weight: 400;
  }
  @media (max-width: ${sizes.S}) {
    align-items: flex-start;
  }
`;
export const AuctionDetailImageRow = styled(AuctionDetailItemRow)`
  display: flex;
  align-items: center;
  position: absolute;
  top: 12px;
  left: 12px;
  width: calc(100% - 24px);
  justify-content: space-between;
  cursor: pointer;
`;

export const Divider = styled.div`
  width: 100%;
  margin: 6px 16px;
  border-bottom: 1px solid ${colors.mischka};
`;

export const AuctionDetailItemRowLeft = styled(AuctionDetailItemRow)`
  justify-content: left;
  line-height: 20px;
  margin-bottom: 5px;
  span {
    margin-left: 4px;
  }
  > div:first-child:after {
    content: '';
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: grey;
    display: inline-block;
    margin: 0 5px;
  }
`;

export const AuctionDetailItemModelRow = styled(AuctionDetailItemRow)`
  display: flex;
  align-items: flex-start;
  font-size: 18px;
  color: #263a54;
  font-family: BasierCircle-Medium;
  line-height: 20px;
  margin-bottom: 1px;
`;

export const AuctionModelName = styled(AuctionDetailItemRow)`
  font-family: BasierCircle;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 240px;
  width: 100%;
  display: block;
  margin-top: 3px;
  margin-bottom: 2px;
`;

export const AuctionDetailMileageRow = styled(AuctionDetailItemRow)`
  font-weight: 600;
  font-size: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  width: fit-content;
  span:first-child:after {
    content: '';
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #263a54;
    display: inline-block;
    margin: 0 5px;
    margin-bottom: 2px;
  }
`;

export const AuctionDetailItemInfo = styled.div`
  margin-top: 15px;
`;

export const AuctionDetailMilleageRow = styled.div`
  line-height: 20px;
  // margin-bottom: 4px;
  span {
    // max-width: 210px;
  }

  p {
    max-width: 203px;
  }

  @media (max-width: ${sizes.XL}) {
    p {
      max-width: 100%;
    }
  }
`;

export const AuctionDetailsActionsRow = styled(AuctionDetailItemRow)`
  align-items: flex-end;
  @media (max-width: ${sizes.XL}) {
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    #set-max-btn {
      width: calc(100% - 20px);
      span {
        margin: 0 auto;
      }
    }
  }
`;

export const LikeContainer = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border: 1px solid #e1e8fa;
  border-radius: 8px;
  background: #ffffff;
  cursor: pointer;
  z-index: 10;
`;
