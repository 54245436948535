import colors from '@pickles/shared/utils/colors';
import styled from 'styled-components';
import sizes from '../../util/sizes';
import { IconButtonContainer } from '../Shared/Button/IconButton.style';

interface IModalWrapper {
  isModalVisible: boolean;
}

export const AuctionDetailItemsContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin-top: 24px;
  cursor: pointer;

  @media (max-width: 1800px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${sizes.S}) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
  }
`;

export const AuctionDetailItemContainer = styled.div<IModalWrapper>`
  cursor: ${({ isModalVisible }) => (isModalVisible ? '' : 'pointer')};
  position: relative;
  margin: 0 auto;
  min-width: 33.33%;
  padding-left: 3px;
  padding-right: 3px;
  width: 100%;
  max-width: 370px;

  .image-gallery {
    width: 100%;
  }

  .image-gallery-image {
    max-height: initial;
  }

  // TODO:: add box shadow

  @media (max-width: ${sizes.S}) {
    width: 100%;
  }

  > div {
    margin-top: ${({ isModalVisible }) => (isModalVisible ? 0 : '4px')};
}
  svg {
    width: 24px !important;
  }
  > div {
    font-family: BasierCircle;
  }
`;

export const StatusContainer = styled.div`
  display: flex;
`;

export const HorizontalsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 120px);
`;

export const RightBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  /* width: 40%; */
`;

export const BidLabelBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 5px;
`;

export const BidLabel = styled.div`
  font-size: 13px;
  color: #334d70;
  line-height: 20px;
`;

export const AmountBlock = styled.div`
  font-size: 18px;
  color: #263a54;
  font-family: BasierCircle-Medium;
  line-height: 20px;
`;

export const BorderRight = styled.div`
  position: absolute;
  background-color: white;
  height: 20%;
  width: 20%;
  top: 50%;
  right: -2%;
`;

export const BorderTop = styled.div`
  position: absolute;
  background-color: white;
  height: 20%;
  width: 20%;
  top: -2px;
  left: 50%;
`;

export const BorderBottom = styled.div`
  position: absolute;
  background-color: white;
  height: 20%;
  width: 20%;
  bottom: 21%;
  left: 20%;
`;

export const BorderWrapper = styled.div<any>`
  background-color: white;
  padding: 6px;
  margin: -6px;
  /* isVisible */
  border: ${(props) => (props.isVisible ? '2px solid red' : '2px solid white')};
  /* border: 2px solid red; */
  border-radius: 11px;
  display: flex;
`;

export const ImageContainer = styled.div`
  display: flex;
  height: 280px;
  /* max-height: 20.4vw; */
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  overflow: hidden;
  z-index: 1;

  /* @media (max-width: ${sizes.L}) {
    height: initial;
  } */
`;

export const AuctionDetailItemRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  line-height: 20px;
  color: ${colors.san_juan_grey};
  z-index: 1;
  text-align: left;
  white-space: nowrap;

  span {
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
  }
`;
export const AuctionDetailImageRow = styled(AuctionDetailItemRow)`
  display: flex;
  align-items: center;
  position: absolute;
  top: 16px;
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  justify-content: space-between;

  & ${IconButtonContainer} {
    margin-left: 0;
  }
`;

export const AuctionDetailImageRowCell = styled(AuctionDetailItemRow)`
  display: flex;
  align-items: center;
  > div:nth-child(2) {
    padding: 10.5px 14px;
    height: 36px;
    width: 110px;
    span {
      margin-right: 6px;
    }
  }
`;

export const LikeButtonWrapper = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border: 1px solid #e1e8fa;
  border-radius: 8px;
  background: #ffffff;
  cursor: pointer;
  z-index: 10;
`;

export const AuctionDetailItemRowLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  line-height: 20px;
  color: ${colors.san_juan_grey};
  z-index: 1;
  justify-content: left;

  > p {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: ${colors.bali_hai_grey};
    display: inline-block;
    margin: 0 5px;
  }

  /* span {
    font-size: 15px;
    font-family: 'BasierCircle-Medium';
    font-weight: 400;
    margin-left: 4px;
  } */
`;

export const AuctionDetailItemModelRow = styled(AuctionDetailItemRow)`
  font-size: 18px;
  color: #263a54;
  font-family: BasierCircle-Medium;
`;

export const AuctionModelName = styled(AuctionDetailItemRow)`
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  margin-top: 3px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: initial;
`;

export const MileageRowContainer = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  width: fit-content;
  color: ${colors.san_juan_grey};
  margin-top: 5px;
  margin-bottom: 4px;

  div {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: ${colors.rhino_black};
    display: inline-block;
    margin: 0 5px;
    margin-bottom: 2px;
  }
`;

export const AuctionDetailItemInfo = styled.div`
  padding-top: 7px;
`;

export const SetMaxButton = styled.div`
  display: flex;
  justify-content: center;
  background: ${colors.blumine_main_blue};
  border-radius: 8px;
  /* max-width: 129px; */
  min-width: 118px;
  padding: 6px;
  line-height: 26px;
  color: #fff;
  cursor: pointer;
  flex-shrink: 0;
  white-space: nowrap;
  span {
    font-family: 'BasierCircle';
    font-weight: 600;
    margin: 0px auto;
    font-size: 15px;
  }
  > img {
    margin-right: 10px;
  }
`;

export const CancelButton = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 8px;
  padding: 6px 15px;
  white-space: nowrap;
  flex-shrink: 0;
  border: 1px solid ${colors.mischka};

  span {
    font-size: 15px;
    line-height: 26px;
    font-weight: 500;
    color: ${colors.san_juan_grey};
    text-align: center;
  }
`;

export const ViewButton = styled.div`
  display: flex;
  justify-content: center;
  padding: 6px 15px;
  background: ${colors.selago};
  border-radius: 8px;
  color: ${colors.blumine_main_blue};
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  cursor: pointer;
  white-space: nowrap;
  flex-shrink: 0;

  @media (min-width: ${sizes.L}) {
    width: 120px;
  }
`;

export const CarDetailStatus = styled.div`
  position: absolute;
  left: 54px;
  z-index: 100;
  padding: 0 10px;
  color: #334d70;
  background: #f2f6ff;
  border-radius: 6px;
  text-transform: uppercase;
  font-family: BasierCircle-Semibold;
  font-size: 12px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PrebidsIconContainer = styled.div`
  background: #1c4d8e;
  border-radius: 8px;
  margin: 8px;
  padding: 3px 6px;
`;

export const PrebidsStatusContent = styled.div`
  display: flex;
  align-items: center;
  background: ${colors.zircon_light_blue};
  margin: 12px 0px;
  border-radius: 8px;
  padding: 8px;
`;

export const PrebidsStatusLabel = styled.div`
  font-family: BasierCircle;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: ${colors.san_juan_grey};
  margin-left: 9px;
`;

export const PrebidsStatusValue = styled.div`
  font-family: BasierCircle-Medium;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.san_juan_grey};
  margin-left: 4px;
`;

export const BidModalContainer = styled.div`
  width: 100%;
  /* height: 100px; */
  position: absolute;
  bottom: 52px;
  z-index: 4;
`;

export const AuctionPlaceMapContainer = styled.div`
  flex-direction: row;
  display: flex;
  overflow: hidden;
  align-items: center;

  p {
    font-size: 14px;
    line-height: 20px;
    font-family: BasierCircle;
    text-align: left;
    color: ${colors.san_juan_grey};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
    margin-left: 6px;
  }
`;

export const PinIconWrapper = styled.div`
  flex-shrink: 0;
`;
