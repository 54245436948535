import { FC } from 'react';
import Portal from '.';
import { SharedModal } from '../Shared/SharedModal';

interface ModalPortalProps {
  url: string;
  isOpen: boolean;
  onClickClose: () => void;
}

const ShareModalPortal: FC<ModalPortalProps> = ({ url, onClickClose, isOpen }) => {
  return (
    <Portal wrapperId="modal-wrapper-portal">
      <SharedModal url={url} onClickOutside={onClickClose} isOpened={isOpen} />
    </Portal>
  );
};

export default ShareModalPortal;
