import colors from '@pickles/shared/utils/colors';
import styled from 'styled-components';
import { SlidingUp } from '../../../styles/animations';
import sizes from '../../../util/sizes';

interface ModalProps {
  size?: string;
  isOpened?: boolean;
  isFromLiveAuctionPage?: boolean;
  scrollTop?: number;
}
const modalBoxShadow = ' 0px 6px 50px rgba(0, 0, 0, 0.09);';

export const CloseIcon = styled.img`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

export const Modal = styled.div<ModalProps>`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: ${({ scrollTop = 0 }) => scrollTop}px;
  display: ${({ isOpened }) => (isOpened ? 'block' : 'none')};
  background: rgba(49, 54, 62, 0.6);
  z-index: 100;
  padding: ${({ isFromLiveAuctionPage }) => (isFromLiveAuctionPage ? '40px 80px' : '0')};
  overflow: auto;
  @media (max-width: ${sizes.L}) {
    padding: ${({ isFromLiveAuctionPage }) => (isFromLiveAuctionPage ? '40px' : '0')};
  }
  @media (max-width: ${sizes.M}) {
    padding: ${({ isFromLiveAuctionPage }) => (isFromLiveAuctionPage ? '40px 20px' : '0')};
  }
`;

export const ModalDialog = styled.div<ModalProps>`
  position: relative;
  max-width: calc(100% - 40px);
  width: ${({ isFromLiveAuctionPage }) => (isFromLiveAuctionPage ? '100%' : 'fit-content')};
  height: ${({ size }) => (size === 'sm?' ? '360px' : 'initial')};
  // overflow: auto;
  ${({ isFromLiveAuctionPage }) => !isFromLiveAuctionPage && 'margin: 0 auto'};
  ${({ isFromLiveAuctionPage }) => !isFromLiveAuctionPage && 'margin-top: 100px'};
  border: none;
  box-shadow: ${modalBoxShadow};
  padding: 21px 36px;
  background: #fff;
  border-radius: 10px;
  animation: ${SlidingUp};
  animation-duration: 0.3s;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ModalActions = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 1em;
`;

export const ModalCloseButton = styled.div`
  position: absolute;
  top: -12px;
  right: -10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: ${colors.rhino_black};

  &:before,
  &:after {
    content: '';
    height: 18px;
    width: 2px;
    transform: rotate(-45deg);
    background: #fff;
    position: absolute;
    top: 6px;
    right: 14px;
    border-radius: 2px;
    display: block;
  }

  &:after {
    transform: rotate(45deg);
  }
`;
