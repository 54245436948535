import React, { useEffect, useState, useRef, MouseEventHandler, FC } from 'react';
import { ShareSocial } from 'react-share-social';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import {
  Modal,
  ModalCloseButton,
  ModalDialog,
  ShareContentWrapper,
  ShareDescription,
  ShareIconsRow,
  ShareLinkCopy,
  ShareTitle,
} from './styles';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import { shareFbBlue, shareLIBlue, shareTwitBlue, shareWhatsappBlue } from '../Icons';

interface SharedProps {
  isOpened: boolean;
  onClickOutside: () => void;
  url: string;
  size?: string;
}

export const SharedModal: FC<SharedProps> = ({ isOpened = false, onClickOutside, size, url }) => {
  const [el] = useState(document.createElement('div'));
  const modalRef = useRef<HTMLDivElement>(el);
  const { t } = useTranslation();

  const onClickHandler: MouseEventHandler = (e) => {
    e.stopPropagation();
    onClickOutside();
  };

  const closeByPress = (e: any) => {
    if (e.keyCode === 27) {
      onClickOutside();
    }
  };

  useEffect(() => {
    if (isOpened) {
      document.addEventListener('keydown', closeByPress);
    }
    return () => {
      document.removeEventListener('keydown', closeByPress);
    };
  }, [isOpened]);

  return (
    <Modal isOpened={isOpened} onClick={onClickHandler}>
      <ModalDialog onClick={(e) => e.stopPropagation()} ref={modalRef} size={size}>
        <ShareContentWrapper>
          <ShareTitle>{t('titles:share')}</ShareTitle>
          <ShareDescription style={{ paddingBottom: '10px' }}>
            {t('labels:share_via')}
          </ShareDescription>
          <ShareIconsRow>
            <FacebookShareButton url={url}>
              <Image src={shareFbBlue} alt="Facebook" />
            </FacebookShareButton>
            <WhatsappShareButton url={url}>
              <Image src={shareWhatsappBlue} alt="Whatsapp" />
            </WhatsappShareButton>
            <LinkedinShareButton url={url}>
              <Image src={shareLIBlue} alt="Linked In" />
            </LinkedinShareButton>
            <TwitterShareButton url={url}>
              <Image src={shareTwitBlue} alt="Twitter" />
            </TwitterShareButton>
          </ShareIconsRow>
          <ShareDescription>{t('labels:copy_link')}</ShareDescription>
          <ShareLinkCopy>
            <ShareSocial url={url} socialTypes={[]} />
          </ShareLinkCopy>
        </ShareContentWrapper>
        <ModalCloseButton onClick={() => onClickOutside()} />
      </ModalDialog>
    </Modal>
  );
};
